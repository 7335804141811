<template>
  <div>
    <div v-if="_fileData">
      <div class="top-columns">
        <div>
          <h2 class="invoice-title">Custom columns</h2>
          <table class="table table-sm">
            <thead class="header">
              <th v-for="h in ['Action', 'Name', 'Value']">{{ h }}</th>
            </thead>
            <tbody class="body">
              <tr v-for="(column, index) in customCols">
                <td class="align-middle text-center">
                  <CButton
                    name="add"
                    @click="onPlusClick(index)"
                    class="Buttons"
                    v-c-tooltip="{ content: 'Add row', placement: 'top' }"
                    size="sm"
                  >
                    <CIcon name="cil-plus" />
                  </CButton>
                  <CButton
                    name="delete"
                    @click="onMinusClick(index)"
                    class="Buttons ml-12"
                    v-c-tooltip="{ content: 'Delete row', placement: 'top' }"
                    size="sm"
                    :disabled="
                      disabledCust.includes(column[0]) &&
                      customCols.filter((a) => a[0] == column[0]).length == 1
                    "
                  >
                    <CIcon name="cil-minus" />
                  </CButton>
                </td>
                <td class="align-middle">
                  <CInput
                    class="input"
                    :class="{ error: checkSpecialChars(column[0]) }"
                    :value.sync="column[0]"
                    :disabled="
                      disabledCust.includes(column[0]) &&
                      customCols.filter((a) => a[0] == column[0]).length == 1
                    "
                    :placeholder="'New column'"
                  />
                </td>
                <td class="align-middle">
                  <CInput
                    class="input"
                    :value.sync="column[1]"
                    :placeholder="'New value'"
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div v-if="filteredFileCols && filteredFileCols.length > 0">
          <h2 class="invoice-title">Add new columns</h2>
          <ul class="new-columns">
            <li
              class="new-columns__column"
              v-for="(column, k) in filteredFileCols"
              :key="k"
            >
              <button
                class="new-columns__btn cus-button"
                @click="add_column(column)"
              >
                {{ column }}
              </button>
            </li>
          </ul>
        </div>
      </div>

      <h2 class="invoice-title mt30">Data structure</h2>

      <div class="CheckSwitch">
        <input
          type="checkbox"
          v-model="all_checked"
          color="primary"
          @input="(event) => check_all(all_checked)"
        />
        <span class="CheckText">Check all</span>
      </div>
      <div class="table-responsive">
        <table class="table table-sm">
          <thead class="header">
            <template
              v-for="h in [
                'Include',
                'Column in the file',
                'Column in the database',
                'Type',
                'Unique',
                'Anonymize',
              ]"
            >
              <th
                :class="
                  [
                    'Column in the file',
                    'Column in the database',
                    'Type',
                  ].includes(h)
                    ? 'tr-min-width'
                    : ''
                "
              >
                {{ h }}
              </th>
            </template>
            <th v-if="showFormat" class="tr-min-width">Format</th>
            <th>Comment</th>
            <!-- <th v-if="showComments">Warning</th> -->
            <th>Warning</th>
          </thead>
          <tbody class="body">
            <tr
              v-for="(column, index) in _fileData"
              :key="index + column.last_fname"
            >
              <td class="align-middle text-center">
                <input
                  type="checkbox"
                  id="checkbox"
                  class="input-checkbox input"
                  v-model="column.include"
                />
              </td>
              <!-- <td class="align-middle">
                  <span v-c-tooltip="{ content: column['fname'], placement: 'top' }">
                    {{ column['fname'].length < 16 ? column['fname'] : column['fname'].slice(0, 13) + "..."}}
                  </span></td> -->
              <td class="align-middle">
                <CSelect
                  class="Dropdown input"
                  :value.sync="column.last_fname"
                  :options="[''].concat(fileColumns)"
                  @change="updateDropdowns(column.last_fname, index)"
                />
                <!-- <span
                v-if="
                  !fileColumns.includes(column.last_fname) && column.include
                "
                class="missedWarning"
                >!</span
              > -->
              </td>
              <td class="align-middle InputCol">
                <CInput
                  :value.sync="column.renamed"
                  :disabled="
                    flow == 'reuse' &&
                    existing_columns.includes(column.renamed) &&
                    _fileData.filter((a) => a.renamed == column.renamed)
                      .length == 1
                  "
                  class="Inputs"
                  :class="{ 'invalid-input': !validate(column.renamed) }"
                />
                <span class="input-subtext" v-if="!validate(column.renamed)">
                  Special symbols are not allowed
                </span>
              </td>
              <td>
                <CSelect
                  class="Dropdown input"
                  :value.sync="column.dtype"
                  :options="[
                    'Numeric',
                    'String',
                    'Date',
                    'Boolean',
                    'Country',
                    'City',
                  ]"
                  :disabled="
                    flow == 'reuse' && existing_columns.includes(column.renamed)
                  "
                />
              </td>
              <td class="align-middle text-center">
                <input
                  type="checkbox"
                  id="checkbox"
                  v-model="column.unique"
                  :disabled="
                    flow == 'reuse' && existing_columns.includes(column.renamed)
                  "
                />
              </td>
              <td class="align-middle text-center">
                <input
                  :disabled="
                    flow == 'reuse' && existing_columns.includes(column.renamed)
                  "
                  type="checkbox"
                  id="checkbox"
                  :checked="column.anonimized"
                  @change="(event) => onRandomize(event, column)"
                />
              </td>
              <td class="align-middle InputCol" v-if="column.dtype == 'Date'">
                <CSelect
                  class="Dropdown input"
                  :value.sync="column.date_format"
                  :options="[
                    '[D][M][Y]',
                    '[M][D][Y]',
                    '[Y][D][M]',
                    '[Y][M][D]',
                  ]"
                />
              </td>
              <td
                class="align-middle"
                v-if="showFormat && column.dtype != 'Date'"
              ></td>
              <td class="align-middle InputCol">
                <CInput
                  :value.sync="column.comment"
                  class="input"
                  :disabled="
                    flow == 'reuse' && existing_columns.includes(column.renamed)
                  "
                ></CInput>
              </td>
              <td
                v-if="
                  !fileColumns.includes(column.last_fname) && column.include
                "
                class="missedWarning"
              >
                <span
                  class="flex-center"
                  v-c-tooltip="'the column should match'"
                >
                  <CIcon
                    class="icon-size StatusFailed"
                    name="xIcon"
                    size="custom"
                  />
                </span>
              </td>
              <td
                class="align-middle Comment"
                v-else-if="comments[column.last_fname]"
              >
                {{
                  Array.from(comments[column.last_fname])
                    .filter((a) => a != "not unique")
                    .join(", ")
                }}
                <br />
                <span
                  style="color: red"
                  v-if="
                    Array.from(comments[column.last_fname]).includes(
                      'not unique'
                    )
                  "
                >
                  not unique</span
                >
              </td>
              <td
                class="align-middle"
                v-else-if="!comments[column.last_fname]"
              ></td>
            </tr>

            <!-- <tr
            v-if="fileCols.length > 0"
            v-for="column in fileCols.filter(
              (el) => !fileData.map((a) => a.last_fname).includes(el)
            )"
          >
            <td class="align-middle text-center"></td>
            <td class="align-middle">{{ column }}</td>
            <td class="align-middle">
              <CButton @click="add_column(column)"
                ><CIcon name="cil-plus" />Add as a new column</CButton
              >
            </td>
          </tr> -->
          </tbody>
        </table>
        <div class="text-secondary" style="text-align: end">
          Profilling is based on first 1000 rows
        </div>
      </div>

      <CAlert v-if="missedCols" color="warning"
        >Some columns are missing in file!</CAlert
      >
      <CAlert v-if="notUniqueValues" color="warning"
        >Values in unique columns should be unique!</CAlert
      >
      <CAlert v-if="notUniqueNames" color="warning"
        >Column names are not unique!</CAlert
      >
      <CAlert v-if="emptyCustoms" color="warning"
        >Custom columns shouldn't be empty!</CAlert
      >
      <CAlert v-if="emptyNames" color="warning"
        >Column names shouldn't be empty!</CAlert
      >
      <CAlert v-if="containSpecialChars" color="warning"
        >Column names shouldn't contain special characters or spaces!</CAlert
      >
      <div
        class="bottom-buttons"
        style="display: flex; flex-direction: column; align-items: flex-end"
      >
        <CButton
          class="mazarsButton m0"
          :disabled="
            Boolean(
              updating ||
                isLoadDataDisabled ||
                missedCols ||
                notUniqueValues ||
                notUniqueNames ||
                emptyCustoms ||
                emptyNames ||
                containSpecialChars ||
                invalidColumnName
            ) || notUniqueColumn
          "
          @click="send_data"
          data-cy="button-ingest"
        >
          Load data
        </CButton>
        <p style="color: red" v-if="notUniqueColumn">
          {{ `Column ${notUniqueColumn_name} is not unique` }}
        </p>
      </div>
    </div>
    <div v-else>
      <div class="spinner-border text-primary" role="status"></div>
    </div>
  </div>
</template>

<script>
import { TemplatesService } from "@/services/templates";
import { CAlert, CButton } from "@mz/coreui-vue";
import AnonymizeButton from "./AnonymizeButton.vue";
export default {
  name: "InvoiceTable",
  props: [
    "fileData",
    "flow",
    "page",
    "headers",
    "is_edited",
    "fileColumns",
    "custCols",
    "inputValues",
    "isNew",
    "isLoadDataDisabled",
  ],
  components: { CButton, CAlert },
  data() {
    return {
      comments: {},
      updating: 0,
      all_checked: true,
      // buttonBlocked: false,
      disabledCust: [],
      fileCols: [],
      customCols: [],
      existing_columns: [],
      anonymized: false,
      notUniqueColumn: false,
      notUniqueColumn_name: "",
      _fileData: [],
    };
  },
  watch: {
    changedItems: {
      handler(val, oldVal) {
        this.$emit("onLoadDataDisable", false);
        // this.buttonBlocked = false;
        if (!this.is_edited) {
          let changed = val.filter(
            (a) => JSON.stringify(oldVal[val.indexOf(a)]) != JSON.stringify(a)
          );

          let changed_index = val.indexOf(changed[0]);
          var changes = Object.keys(changed[0]).filter(
            (key) => oldVal[changed_index][key] != val[changed_index][key]
          );
          var ignore = changes.every(function (val) {
            return ["fnames", "comment", "renamed"].indexOf(val) >= 0;
          });
          if (!ignore) {
            this.profillateNew(changed[0]["last_fname"]);
          }
        }
        if (val.every((a, i, arr) => a.include === true)) {
          this.all_checked = true;
        } else {
          this.all_checked = false;
        }
      },
      deep: true,
    },
    fileColumns: {
      immediate: true,
      handler() {
        this.handleFileData(this.fileData, this.fileColumns);
      },
    },
    fileData: {
      immediate: true,
      handler() {
        this.handleFileData(this.fileData, this.fileColumns);
      },
    },
    comments: {
      handler(newComments) {
        this.notUniqueColumn = Object.keys(newComments).some((column) =>
          Array.from(newComments[column]).includes("not unique")
        );
        this.notUniqueColumn_name = "";
        Object.keys(newComments).forEach((column) => {
          if (Array.from(newComments[column]).includes("not unique")) {
            this.notUniqueColumn_name = column;
          }
        });
      },
      deep: true,
    },
  },

  computed: {
    showComments() {
      return (
        this.comments &&
        Object.keys(this.comments).filter((a) => this.comments[a] != "")
          .length > 0
      );
    },
    filteredFileCols() {
      return this.fileCols.filter(
        (el) => !this._fileData.map((a) => a.last_fname).includes(el)
      );
    },
    showFormat() {
      return this._fileData.map((a) => a.dtype).includes("Date");
    },
    changedItems: function () {
      return this._fileData ? JSON.parse(JSON.stringify(this._fileData)) : [];
    },
    changedCustoms: function () {
      return JSON.parse(JSON.stringify(this.custCols));
    },
    missedCols() {
      return this._fileData
        .filter((a) => a.include == true)
        .map((a) => a.last_fname)
        .filter((el) => !this.fileCols.includes(el)).length;
    },
    notUniqueValues() {
      if (this.comments.length) {
        return Object.values(this.comments).filter((el) =>
          el.includes("not unique")
        ).length;
      } else {
        return false;
      }
    },
    containSpecialChars() {
      return this.customCols.some((i) => this.checkSpecialChars(i[0]));
    },
    notUniqueNames() {
      let unique_cust = this.customCols
        .map((a) => a[0].toString().toLowerCase())
        .filter(
          (value, index, array) =>
            array.indexOf(value) === index && value !== ""
        );
      let unique_renamed = this._fileData
        .map((a) => a.renamed.toString().toLowerCase())
        .filter((value, index, array) => array.indexOf(value) === index);
      let all_not_unique_names = this.customCols
        .map((a) => a[0].toString().toLowerCase())
        .filter((value) =>
          this._fileData
            .map((a) => a.renamed.toString().toLowerCase())
            .includes(value)
        );
      return (
        this.customCols.filter((a) => a[0] != "").length !=
          unique_cust.length ||
        this._fileData.length != unique_renamed.length ||
        all_not_unique_names.length
      );
    },
    emptyCustoms() {
      return Boolean(
        this.customCols.filter((a) => a[1] == "" && a[0] != "").length
      );
    },
    emptyNames() {
      return Boolean(
        this.customCols.filter((a) => a[0] == "" && a[1] != "").length ||
          this._fileData.filter((a) => a.renamed == "").length
      );
    },

    invalidColumnName() {
      return this._fileData.some((i) => !this.validate(i.renamed));
    },
  },

  mounted() {
    this.customCols = [...this.custCols];
    this.existing_columns = [...this._fileData.map((a) => a.renamed)];
    this.customCols.map((a) => (a[1] = ""));
    if (!this.customCols.length) {
      this.customCols = [["", ""]];
    }

    if (!this.is_edited) {
      this.profillateNew();
    }
    this.disabledCust = [...this.custCols].map((a) => a[0]);
  },

  updated() {
    this.$emit("render-ended");
  },

  methods: {
    handleFileData(data, columns) {
      this.fileCols = [...columns];
      this._fileData = data.map((d) => {
        const existingColumn = d.fnames.find((c) => columns.includes(c));
        if (existingColumn) {
          d.last_fname = existingColumn;
        }
        return d;
      });
    },
    send_data() {
      this.$emit("upload-data", {
        newData: this._fileData,
        custCols: this.customCols.filter((a) => a[0] != ""),
        anonymized: this.anonymized,
      });
    },

    validate(column_name) {
      const pattern = /^[a-zA-Z0-9_() ]+$/;
      return pattern.test(column_name);
    },

    onRandomize(e, item) {
      item.anonimized = e.target.checked;
      if (e.target.checked) {
        this.profillateNew("", [{ fnames: item.fnames, dtype: item.dtype }]);
      }
    },

    checkSpecialChars(item) {
      return item && !/^[a-zA-Z0-9]([a-zA-Z0-9_-]*[a-zA-Z0-9])?$/.test(item);
    },

    check_all(e) {
      this._fileData.map((a) => (a["include"] = !e));
      this.$forceUpdate();
    },

    updateDropdowns(name, index) {
      this._fileData.map((a, i) => {
        if (a.last_fname == name && index != i) {
          a.last_fname = " ";
        }
      });
    },

    async profillateNew(column_name = "", anonimize = []) {
      // if (this.comments) {
      //   Object.keys(this.comments).map((key) => this.comments[key] = "loading...")
      // }
      this.updating = 1;
      if (column_name) {
        this.comments[column_name] = ["loading..."];
      }
      var { data, res } = await TemplatesService.profillate({
        tableData: this._fileData,
        page: this.page ? this.page : "_",
        headers: this.headers,
        column_name: column_name,
        client_name: this.inputValues.client.name,
        project_name: this.inputValues.project.name,
        anonimize,
      });
      if (res.status !== 200) {
        return alert("Error getting info about file");
      }
      if (data.success && !column_name) {
        this.notUniqueColumn = false;
        this.comments = data.comments;
        this.updating = 0;
      } else if (data.success) {
        if (
          data.comments[column_name] &&
          Object.values(data.comments[column_name]).includes("not unique")
        ) {
          this.notUniqueColumn_name = column_name;
          this.notUniqueColumn = true;
        } else {
          this.notUniqueColumn = false;
        }
        this.comments[column_name] = data.comments[column_name];
        this.updating = 0;
      }
    },

    async check_edited_data() {
      var { data, res } = await TemplatesService.check_reuse({
        templateData: this._fileData.columns,
      });
      if (res.status !== 200) {
        return alert("Error getting info about file");
      }
      if (data.success) {
        this.$emit("get-errors", { errors: data.data, type: "reuse" });
      }
    },

    add_column(column) {
      const emptyColumns = this._fileData.filter((c) => !c.last_fname);
      const existing = emptyColumns.find((c) => c.renamed === column);
      if (existing) {
        this.$set(existing, "last_name", column);
      } else {
        if (emptyColumns.length) {
          this.$set(emptyColumns[0], "last_name", column);
        } else {
          this._fileData.push({
            include: true,
            fnames: [column],
            last_fname: column,
            renamed: column,
            dtype: "String",
            comment: "",
            unique: false,
          });
        }
      }
    },

    onPlusClick(index) {
      this.customCols.push(["", ""]);
      this.$forceUpdate();
    },

    onMinusClick(index) {
      if (this.customCols.length > 1) {
        this.customCols.splice(index, 1);
      } else {
        this.customCols = [["", ""]];
      }
      this.$forceUpdate();
    },
  },
};
</script>

<style lang="scss">
.ml-12 {
  margin-left: 12px;
}
.tr-min-width {
  min-width: 120px;
}
.randomize-content {
  display: flex;
  align-items: center;
  margin-right: 30px;

  .custom-control-label::before {
    width: 36px;
    height: 20px;
    top: 1px;
  }

  .custom-control-input:checked ~ .custom-control-label::before {
    background-color: #3e6bbd !important;
    border-color: #3e6bbd !important;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  }

  .custom-switch .custom-control-label::after {
    width: 16px;
    height: 16px;
    top: 0.2rem;
  }

  .custom-switch .custom-control-input:checked ~ .custom-control-label::after {
    transform: translateX(0.92rem);
  }

  .custom-switch .custom-control-label::before {
    border-radius: 1rem;
  }

  label {
    display: flex;
    align-items: center;
    margin: 0;
    padding: 0;
  }
}
.mazarsButton.m0 {
  margin: 0;
}
.randomize-btn__icon {
  height: 20px;
  width: 20px;
  border: 1px solid #274b8e;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  cursor: pointer;
  font-size: 12px;
}
.randomize-text {
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  color: #0c2044;
  margin: 0 16px 0 6px;
}
.Buttons {
  background: #e7edfb;
  border-radius: 50%;
}
.input {
  .form-control {
    background: #f8f9fc;
    border: 1px solid #e7edfb;
    border-radius: 10px;
    height: 40px;
  }

  &.error {
    input {
      border-color: #dc3545;
    }
  }
}
.Dropdown {
  margin-top: 12px;
  // max-width: 10vw;
}
td {
  vertical-align: middle !important;
}
.bottom-buttons {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 30px;
}
.CheckButton {
  margin: 1rem 0 1rem auto;
  height: 4em;
  width: 10em;
  background-color: #636f83;
  color: white;
  float: right;
}
.Comment {
  max-width: 40vw;
}
.top-columns {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
}
.new-columns {
  display: flex;
  flex-wrap: wrap;
  background-color: #f8f9fc;
  border-radius: 10px;
  border: 1px solid #c1cce7;
  list-style: none;
  padding: 10px;
  width: 100%;
  gap: 10px;

  &__btn {
    border: none;
    background-color: inherit;
    outline: none;
  }
}
.table {
  border-spacing: 0;
  border-collapse: separate;
  color: #0c2044;
  overflow: hidden;
  border-radius: 10px;
  border: 1px solid #c1cce7;
}
.table thead th {
  border-bottom: 0;
  height: 45px;
  vertical-align: middle;
}
.header {
  color: white;
  border-radius: 10px 10px 0px 0px;
  background: linear-gradient(90.87deg, #284c8f 0%, #358ac8 102.63%);

  th {
    border: 1px solid #c1cce7;
    text-align: center;
    border-top: 0;
    border-bottom: 0;
    border-right: 0;
    font-weight: 400;
    font-size: 16px;
    line-height: 18px;
  }
  th {
    white-space: nowrap;
  }
  th:first-child {
    border-radius: 10px 0px 0px 0px;
    border-left: 0;
  }

  th:last-child {
    border-radius: 0px 10px 0px 0px;
    border-right: 0;
  }
}
.body {
  td {
    border: 1px solid #c1cce7;
    border-bottom: 0;
    border-right: 0;

    &:first-child {
      border-left: 0;
    }
  }
}
.invoice-title {
  font-weight: 400;
  font-size: 20px;
  line-height: 23px;
  color: #0c2044;
  margin-bottom: 20px;
  margin-top: 10px;
}
.mazarsButton {
  float: right;
  align-items: center;
  background-color: #0071ce;
  border: 1px solid #0071ce;
  border-radius: 0;
  border-bottom-left-radius: 1.75rem;
  color: #fff;
  cursor: pointer;
  display: inline-flex;
  font-size: 1rem;
  font-weight: 500;
  justify-content: center;
  line-height: 1;
  max-width: 100%;
  min-height: 3rem;
  min-width: 200px;
  overflow: hidden;
  padding: 0.75rem 1.5rem;
  position: relative;
  text-decoration: none !important;
  transition: all 0.3s;
  vertical-align: middle;
  margin: 30px;
}
.mr0 {
  margin-right: 0;
}
.spinner-border {
  height: 3rem;
  width: 3rem;
  animation-duration: 2s;
  margin-left: calc(50% - 1.5rem);
  margin-bottom: 30px;
  position: relative;
}
.CheckSwitch {
  display: flex;
  margin-bottom: 10px;
}
.CheckText {
  margin-left: 7px;
  margin-top: 2px;
  display: flex;
}
.Inputs {
  width: 15vw;
}
.InputCol {
  width: 15vw;
}
.missedWarning {
  color: red;
  font-weight: 800;
  font-size: x-large;
}
.mt30 {
  margin-top: 30px;
}
</style>
