<template>
  <div class="anonymize-btn">
    <span
      v-c-tooltip="{
        content:
          'Randomized data doesn`t contain critical info, but still useful for analysis',
        placement: 'top',
      }"
      class="anonymize-btn__icon"
      >i</span
    >

    <SwitchButton @onChange="(v) => $emit('onChange', v)" :title="title" />
  </div>
</template>
<script>
import SwitchButton from "./SwitchButton.vue";

export default {
  name: "AnonymizeButton",
  components: { SwitchButton },
  props: {
    title: String,
  },
};
</script>
<style lang="scss" scoped>
.anonymize-btn {
  display: flex;
  align-items: center;

  &__icon {
    height: 42px;
    width: 42px;
    border: 1px solid #274b8e;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    margin-right: 10px;
    cursor: pointer;
    font-size: 18px;
  }
}
</style>
