<template>
  <div class="custom-switcher">
    <label class="custom-switcher__label">
      <input
        type="checkbox"
        :checked="value"
        @change="(e) => $emit('onChange', e.target.checked)"
        class="custom-switcher__checkbox"
      />
      <span class="custom-switcher__text">{{ title }}</span>
    </label>
  </div>
</template>
<script>
export default {
  name: "SwitchButton",
  props: {
    title: String,
    value: Boolean,
  },
};
</script>
<style lang="scss" scoped>
.custom-switcher {
  display: inline-block;
  &__checkbox {
    display: none;
  }

  &__label {
    height: 42px;
    min-width: 180px;
    border: 2px solid #274b8e;
    border-radius: 10px;
    display: flex;
    align-items: center;
    padding: 0 2px;
    cursor: pointer;
    margin: 0;
    position: relative;
  }

  &__text {
    position: absolute;
    left: 1px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    min-width: 100px;
    height: 36px;
    background-color: #bfbfbf;
    font-size: 12px;
    color: #44546a;
    transition: all 300ms ease-in-out;
    border: 1px solid #274b8e;
    padding: 0 10px;
  }

  &__checkbox:checked + .custom-switcher__text {
    right: 2px;
    left: unset;
    background-color: #44546a;
    color: #fff;
  }
}
</style>
