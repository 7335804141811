var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm._fileData)?_c('div',[_c('div',{staticClass:"top-columns"},[_c('div',[_c('h2',{staticClass:"invoice-title"},[_vm._v("Custom columns")]),_c('table',{staticClass:"table table-sm"},[_c('thead',{staticClass:"header"},_vm._l((['Action', 'Name', 'Value']),function(h){return _c('th',[_vm._v(_vm._s(h))])}),0),_c('tbody',{staticClass:"body"},_vm._l((_vm.customCols),function(column,index){return _c('tr',[_c('td',{staticClass:"align-middle text-center"},[_c('CButton',{directives:[{name:"c-tooltip",rawName:"v-c-tooltip",value:({ content: 'Add row', placement: 'top' }),expression:"{ content: 'Add row', placement: 'top' }"}],staticClass:"Buttons",attrs:{"name":"add","size":"sm"},on:{"click":function($event){return _vm.onPlusClick(index)}}},[_c('CIcon',{attrs:{"name":"cil-plus"}})],1),_c('CButton',{directives:[{name:"c-tooltip",rawName:"v-c-tooltip",value:({ content: 'Delete row', placement: 'top' }),expression:"{ content: 'Delete row', placement: 'top' }"}],staticClass:"Buttons ml-12",attrs:{"name":"delete","size":"sm","disabled":_vm.disabledCust.includes(column[0]) &&
                    _vm.customCols.filter((a) => a[0] == column[0]).length == 1},on:{"click":function($event){return _vm.onMinusClick(index)}}},[_c('CIcon',{attrs:{"name":"cil-minus"}})],1)],1),_c('td',{staticClass:"align-middle"},[_c('CInput',{staticClass:"input",class:{ error: _vm.checkSpecialChars(column[0]) },attrs:{"value":column[0],"disabled":_vm.disabledCust.includes(column[0]) &&
                    _vm.customCols.filter((a) => a[0] == column[0]).length == 1,"placeholder":'New column'},on:{"update:value":function($event){return _vm.$set(column, 0, $event)}}})],1),_c('td',{staticClass:"align-middle"},[_c('CInput',{staticClass:"input",attrs:{"value":column[1],"placeholder":'New value'},on:{"update:value":function($event){return _vm.$set(column, 1, $event)}}})],1)])}),0)])]),(_vm.filteredFileCols && _vm.filteredFileCols.length > 0)?_c('div',[_c('h2',{staticClass:"invoice-title"},[_vm._v("Add new columns")]),_c('ul',{staticClass:"new-columns"},_vm._l((_vm.filteredFileCols),function(column,k){return _c('li',{key:k,staticClass:"new-columns__column"},[_c('button',{staticClass:"new-columns__btn cus-button",on:{"click":function($event){return _vm.add_column(column)}}},[_vm._v(" "+_vm._s(column)+" ")])])}),0)]):_vm._e()]),_c('h2',{staticClass:"invoice-title mt30"},[_vm._v("Data structure")]),_c('div',{staticClass:"CheckSwitch"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.all_checked),expression:"all_checked"}],attrs:{"type":"checkbox","color":"primary"},domProps:{"checked":Array.isArray(_vm.all_checked)?_vm._i(_vm.all_checked,null)>-1:(_vm.all_checked)},on:{"input":(event) => _vm.check_all(_vm.all_checked),"change":function($event){var $$a=_vm.all_checked,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.all_checked=$$a.concat([$$v]))}else{$$i>-1&&(_vm.all_checked=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.all_checked=$$c}}}}),_c('span',{staticClass:"CheckText"},[_vm._v("Check all")])]),_c('div',{staticClass:"table-responsive"},[_c('table',{staticClass:"table table-sm"},[_c('thead',{staticClass:"header"},[_vm._l(([
              'Include',
              'Column in the file',
              'Column in the database',
              'Type',
              'Unique',
              'Anonymize',
            ]),function(h){return [_c('th',{class:[
                  'Column in the file',
                  'Column in the database',
                  'Type',
                ].includes(h)
                  ? 'tr-min-width'
                  : ''},[_vm._v(" "+_vm._s(h)+" ")])]}),(_vm.showFormat)?_c('th',{staticClass:"tr-min-width"},[_vm._v("Format")]):_vm._e(),_c('th',[_vm._v("Comment")]),_c('th',[_vm._v("Warning")])],2),_c('tbody',{staticClass:"body"},_vm._l((_vm._fileData),function(column,index){return _c('tr',{key:index + column.last_fname},[_c('td',{staticClass:"align-middle text-center"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(column.include),expression:"column.include"}],staticClass:"input-checkbox input",attrs:{"type":"checkbox","id":"checkbox"},domProps:{"checked":Array.isArray(column.include)?_vm._i(column.include,null)>-1:(column.include)},on:{"change":function($event){var $$a=column.include,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(column, "include", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(column, "include", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(column, "include", $$c)}}}})]),_c('td',{staticClass:"align-middle"},[_c('CSelect',{staticClass:"Dropdown input",attrs:{"value":column.last_fname,"options":[''].concat(_vm.fileColumns)},on:{"update:value":function($event){return _vm.$set(column, "last_fname", $event)},"change":function($event){return _vm.updateDropdowns(column.last_fname, index)}}})],1),_c('td',{staticClass:"align-middle InputCol"},[_c('CInput',{staticClass:"Inputs",class:{ 'invalid-input': !_vm.validate(column.renamed) },attrs:{"value":column.renamed,"disabled":_vm.flow == 'reuse' &&
                  _vm.existing_columns.includes(column.renamed) &&
                  _vm._fileData.filter((a) => a.renamed == column.renamed)
                    .length == 1},on:{"update:value":function($event){return _vm.$set(column, "renamed", $event)}}}),(!_vm.validate(column.renamed))?_c('span',{staticClass:"input-subtext"},[_vm._v(" Special symbols are not allowed ")]):_vm._e()],1),_c('td',[_c('CSelect',{staticClass:"Dropdown input",attrs:{"value":column.dtype,"options":[
                  'Numeric',
                  'String',
                  'Date',
                  'Boolean',
                  'Country',
                  'City',
                ],"disabled":_vm.flow == 'reuse' && _vm.existing_columns.includes(column.renamed)},on:{"update:value":function($event){return _vm.$set(column, "dtype", $event)}}})],1),_c('td',{staticClass:"align-middle text-center"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(column.unique),expression:"column.unique"}],attrs:{"type":"checkbox","id":"checkbox","disabled":_vm.flow == 'reuse' && _vm.existing_columns.includes(column.renamed)},domProps:{"checked":Array.isArray(column.unique)?_vm._i(column.unique,null)>-1:(column.unique)},on:{"change":function($event){var $$a=column.unique,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(column, "unique", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(column, "unique", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(column, "unique", $$c)}}}})]),_c('td',{staticClass:"align-middle text-center"},[_c('input',{attrs:{"disabled":_vm.flow == 'reuse' && _vm.existing_columns.includes(column.renamed),"type":"checkbox","id":"checkbox"},domProps:{"checked":column.anonimized},on:{"change":(event) => _vm.onRandomize(event, column)}})]),(column.dtype == 'Date')?_c('td',{staticClass:"align-middle InputCol"},[_c('CSelect',{staticClass:"Dropdown input",attrs:{"value":column.date_format,"options":[
                  '[D][M][Y]',
                  '[M][D][Y]',
                  '[Y][D][M]',
                  '[Y][M][D]',
                ]},on:{"update:value":function($event){return _vm.$set(column, "date_format", $event)}}})],1):_vm._e(),(_vm.showFormat && column.dtype != 'Date')?_c('td',{staticClass:"align-middle"}):_vm._e(),_c('td',{staticClass:"align-middle InputCol"},[_c('CInput',{staticClass:"input",attrs:{"value":column.comment,"disabled":_vm.flow == 'reuse' && _vm.existing_columns.includes(column.renamed)},on:{"update:value":function($event){return _vm.$set(column, "comment", $event)}}})],1),(
                !_vm.fileColumns.includes(column.last_fname) && column.include
              )?_c('td',{staticClass:"missedWarning"},[_c('span',{directives:[{name:"c-tooltip",rawName:"v-c-tooltip",value:('the column should match'),expression:"'the column should match'"}],staticClass:"flex-center"},[_c('CIcon',{staticClass:"icon-size StatusFailed",attrs:{"name":"xIcon","size":"custom"}})],1)]):(_vm.comments[column.last_fname])?_c('td',{staticClass:"align-middle Comment"},[_vm._v(" "+_vm._s(Array.from(_vm.comments[column.last_fname]) .filter((a) => a != "not unique") .join(", "))+" "),_c('br'),(
                  Array.from(_vm.comments[column.last_fname]).includes(
                    'not unique'
                  )
                )?_c('span',{staticStyle:{"color":"red"}},[_vm._v(" not unique")]):_vm._e()]):(!_vm.comments[column.last_fname])?_c('td',{staticClass:"align-middle"}):_vm._e()])}),0)]),_c('div',{staticClass:"text-secondary",staticStyle:{"text-align":"end"}},[_vm._v(" Profilling is based on first 1000 rows ")])]),(_vm.missedCols)?_c('CAlert',{attrs:{"color":"warning"}},[_vm._v("Some columns are missing in file!")]):_vm._e(),(_vm.notUniqueValues)?_c('CAlert',{attrs:{"color":"warning"}},[_vm._v("Values in unique columns should be unique!")]):_vm._e(),(_vm.notUniqueNames)?_c('CAlert',{attrs:{"color":"warning"}},[_vm._v("Column names are not unique!")]):_vm._e(),(_vm.emptyCustoms)?_c('CAlert',{attrs:{"color":"warning"}},[_vm._v("Custom columns shouldn't be empty!")]):_vm._e(),(_vm.emptyNames)?_c('CAlert',{attrs:{"color":"warning"}},[_vm._v("Column names shouldn't be empty!")]):_vm._e(),(_vm.containSpecialChars)?_c('CAlert',{attrs:{"color":"warning"}},[_vm._v("Column names shouldn't contain special characters or spaces!")]):_vm._e(),_c('div',{staticClass:"bottom-buttons",staticStyle:{"display":"flex","flex-direction":"column","align-items":"flex-end"}},[_c('CButton',{staticClass:"mazarsButton m0",attrs:{"disabled":Boolean(
            _vm.updating ||
              _vm.isLoadDataDisabled ||
              _vm.missedCols ||
              _vm.notUniqueValues ||
              _vm.notUniqueNames ||
              _vm.emptyCustoms ||
              _vm.emptyNames ||
              _vm.containSpecialChars ||
              _vm.invalidColumnName
          ) || _vm.notUniqueColumn,"data-cy":"button-ingest"},on:{"click":_vm.send_data}},[_vm._v(" Load data ")]),(_vm.notUniqueColumn)?_c('p',{staticStyle:{"color":"red"}},[_vm._v(" "+_vm._s(`Column ${_vm.notUniqueColumn_name} is not unique`)+" ")]):_vm._e()],1)],1):_c('div',[_c('div',{staticClass:"spinner-border text-primary",attrs:{"role":"status"}})])])
}
var staticRenderFns = []

export { render, staticRenderFns }